import { Link } from "react-router-dom";
import logo from '../imgs/logo.png';

import '../styles/navbar.css';
import { useEffect, useState } from "react";

export default function Navbar() {
    const [scrollNavbar, setScrollNavbar] = useState(false)

    const onScrollNavbar = () => {
        if(window.scrollY >= 20) {
            setScrollNavbar(true)
        } else {
            setScrollNavbar(false)
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', onScrollNavbar)
    })
    return (
        <>
            <nav className={scrollNavbar ? "activeNavbar": "nav"}>
                <div className="logo">
                    <a href="/">
                        <img src={logo} alt="Ustozweb-Logo" />
                        <span>Ustozweb</span>
                    </a>
                </div>
                <ul className="menu-list">
                    <Link to="/">Bosh sahifa</Link>
                    <Link to="/about">Biz haqimizda</Link>
                    <Link to="services">Xizmatlar</Link>
                    <div className="contact-btn">
                        <Link to={'/contact'}>Kontakt</Link>
                    </div>
                </ul>
            </nav>
        </>
    )
}
